@import 'globalStyles/mixins/font-face';

@include font-face('Roboto Medium', 'fonts/roboto/Roboto-Medium', 400, normal, ttf);
@include font-face('Roboto Regular', 'fonts/roboto/Roboto-Regular', 400, normal, ttf);
@include font-face('Roboto Light', 'fonts/roboto/Roboto-Light', 400, normal, ttf);
@include font-face('Roboto Mono', 'fonts/roboto/RobotoMono-Regular', 400, normal, ttf);

@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-100', 100, normal);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-100italic', 100, italic);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-300', 300, normal);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-300italic', 300, italic);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-regular', 400, normal);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-italic', 400, italic);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-500', 500, normal);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-500italic', 500, italic);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-700', 700, normal);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-700italic', 700, italic);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-900', 900, normal);
@include font-face('Roboto', 'fonts/roboto/roboto-v18-latin-900italic', 900, italic);

@import './roboto';
@import './robotoCondensed';

html {
  font-size: 14px;
  height: 100%;
}

body {
  min-height: 100vh;
  font-family: Roboto;
  overscroll-behavior: none;
  margin: 0 !important;
}
